import { combineReducers } from "redux";
import chapters from "../modules/admin-modules/chapters/Reducer";
import events from "../modules/admin-modules/events/Reducer";
import exams from "../modules/admin-modules/exams/Reducer";
import grades from "../modules/admin-modules/grades/Reducer";
import groups from "../modules/admin-modules/groups/Reducer";
import homeworks from "../modules/admin-modules/homework/Reducer";
import lectures from "../modules/admin-modules/lectures/Reducer";
import lessons from "../modules/admin-modules/lessons/Reducer";
import adminLiveVideos from "../modules/admin-modules/liveVideos/Reducer";
import modelsReducer from "../modules/admin-modules/models/Reducer";
import payments from "../modules/admin-modules/payment/Reducer";
import playlists from "../modules/admin-modules/playlists/Reducer";
import questionsBank from "../modules/admin-modules/questions-bank/Reducer";
import redoRequests from "../modules/admin-modules/redo-requests/Reducer";
import reports from "../modules/admin-modules/reports/Reducer";
import adminSections from "../modules/admin-modules/sections/Reducer";
import students from "../modules/admin-modules/students/Reducer";
import subjects from "../modules/admin-modules/subjects/Reducer";
import tags from "../modules/admin-modules/tags/Reducer";
import teachers from "../modules/admin-modules/teachers/Reducer";
import teacherassistants from "../modules/admin-modules/teachersAssistant/Reducer";
import requests from "../modules/admin-modules/video-requests/Reducer";
import videos from "../modules/admin-modules/videos/Reducer";
import authReducer from "../modules/auth/Reducer";
import studentChapters from "../modules/students-modules/chapters/Reducer";
import chapterExams from "../modules/students-modules/exams/Reducer";
import chapterHomeworks from "../modules/students-modules/homework/Reducer";
import chapterlessons from "../modules/students-modules/lessons/Reducer";
import performance from "../modules/students-modules/performance/Reducer";
import questions from "../modules/students-modules/questions/Reducer";
import sections from "../modules/students-modules/sections/Reducer";
import studentExams from "../modules/students-modules/student-exams/Reducer";
import chaptervideos from "../modules/students-modules/videos/Reducer";
import user from "../modules/user/Reducer";

// import {actions as authActions} from "../modules/auth/Reducer";

const appReducer = combineReducers({
  auth: authReducer,
  user: user,
  lessons: lessons,
  model: modelsReducer,
  teachers: teachers,
  teacherassistants: teacherassistants,
  videos: videos,
  students: students,
  groups: groups,
  grades: grades,
  subjects: subjects,
  chapters: chapters,
  lectures: lectures,
  exams: exams,
  tags: tags,
  playlists: playlists,
  questionsBank: questionsBank,
  studentChapters: studentChapters,
  chapterVideos: chaptervideos,
  chapterLessons: chapterlessons,
  chapterExams: chapterExams,
  events: events,
  questions: questions,
  videoRequests: requests,
  studentExams: studentExams,
  reports: reports,
  payments: payments,
  performance: performance,
  redoRequests: redoRequests,
  homework: homeworks,
  studentHomework: chapterHomeworks,
  sections: sections,
  adminLiveVideos: adminLiveVideos,

  adminSections: adminSections,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
