import Types from "./Types";

const INITIAL_STATE = {
  chapterVideos: [],
  videoDetails: {},
  videoData: {},
  liveVideo: [],
  seenCount: 0,
  otp: "",
  playbackInfo: "",
  count: "",
  loading: false,
  seenLoading: false,
  voucherLoading: false,
};

export default function chaptervideos(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_VIDEOS_BY_CHAPTER_ID_SUCCESS: {
      return {
        ...state,
        chapterVideos: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_VIDEO_BY_ID_SUCCESS: {
      return {
        ...state,
        videoDetails: payload.data,
      };
    }

    case Types.VIDEO_DATA_SUCCESS: {
      return {
        ...state,
        videoData: payload?.result?.data?.data,
      };
    }
    case Types.CREATE_SEEN_COUNT_SUCCESS: {
      let id = payload.payload?.video_id;
      let data = payload.result.data.data;
      const chapterVideos = state.chapterVideos;
      let idx = 0;
      chapterVideos.forEach((table, index) => {
        if (table.id === id) {
          idx = index;
          return;
        }
      });

      chapterVideos[idx].views_count = data;
      return {
        ...state,
        chapterVideos: chapterVideos,
      };
    }
    case Types.UPDATE_SEEN_COUNT_SUCCESS: {
      let id = payload.payload?.video_id;
      let data = payload.result.data.data;
      const chapterVideos = state.chapterVideos;
      let idx = 0;
      chapterVideos.forEach((table, index) => {
        if (table.id === id) {
          idx = index;
          return;
        }
      });
      chapterVideos[idx].views_count.views_count = data?.views_count;
      return {
        ...state,
        chapterVideos: chapterVideos,
      };
    }

    case Types.GET_VIDEO_OTP_SUCCESS: {
      return {
        ...state,
        playbackInfo: payload.playbackInfo,
        otp: payload.otp,
      };
    }

    case Types.GET_LIVE_VIDEO_SUCCESS: {
      return {
        ...state,
        liveVideo: payload.data,
      };
    }

    case Types.MORE_VIDEO_VIEWS_SUCCESS: {
      return {
        ...state,
      };
    }

    case Types.ADD_VIDEOS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.VIDEO_SEEN_LOADING: {
      return {
        ...state,
        seenLoading: payload,
      };
    }

    case Types.VIDEO_VOUCHER_LOADING: {
      return {
        ...state,
        voucherLoading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
