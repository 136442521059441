import { Box, Flex, Input, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Layout from "../../../components/dashboard/Layout";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import CTable from "../../../components/Table/table";
import { getAllLecturesRequest } from "../../../modules/admin-modules/lectures/Actions";
import { attendanceReportRequest } from "../../../modules/admin-modules/reports/Actions";

const AttendanceReport = ({ setAdd, setSelectedData, searchTerm }) => {
  const dispatch = useDispatch();
  const [lecId, setLecId] = useState("");
  const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));

  useEffect(() => {
    if (lecId) dispatch(attendanceReportRequest(lecId, date));
  }, [lecId, date]);

  useEffect(() => {
    dispatch(getAllLecturesRequest());
  }, []);
  const lectures = useSelector((state) => state.lectures.lectures);
  const lecOptions = lectures?.map((data) => {
    return { value: data.id, label: data.title };
  });

  const attendancesReport = useSelector(
    (state) => state.reports.attendancesReport
  );
  const [offset, setPage] = useState(0);

  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const headers = [
    { label: "name", key: "name" },
    { label: "group_name", key: "group_name" },
    { label: "phone", key: "phone" },
  ];
  const Data = attendancesReport?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      name: data?.name,
      group_name: data?.group?.name,
      phone: data?.phone,
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "name",
    },
    {
      Header: " المجموعة",
      accessor: "group_name",
    },
    {
      Header: " Qr Code",
      accessor: "code",
    },
    {
      Header: " رقم  الطالب",
      accessor: "phone",
    },
  ];

  return (
    <Layout>
      <div style={{ minHeight: "400px" }}>
        <Flex
          w="100%"
          my="30px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize="24px" fontWeight="600" color="main_1">
            تقرير الحضور
          </Text>

          <Flex w="60%" justifyContent="space-between" alignItems="center">
            <Select
              options={lecOptions}
              isMulti={false}
              onChange={(data) => {
                setLecId(data?.value);
              }}
              styles={colourStyles}
              placeholder="Select lecture"
            />
            <Input
              placeholder="Select Date and Time"
              size="md"
              type="date"
              w="30%"
              mx="8px"
              onChange={(e) => {
                const formatedDate = dayjs(e.target.value).format("YYYY-MM-DD");
                setDate(formatedDate);
              }}
            />
            <Box
              mx="10px"
              border="1px solid gray"
              borderRadius="8px"
              padding="10px"
            >
              <CSVLink
                headers={headers}
                data={Data?.length != 0 ? Data : []}
                filename={"attendence-report.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </Box>
            <LogoutMenu />
          </Flex>
        </Flex>

        <CTable
          selectedData={viewData}
          Columns={columns}
          Data={Data}
          path={"openModel"}
          Actions
          Title={``}
          subTitle=""
          btnTitle=""
          placeHolder=""
          noSearchBar={true}
          noFilter={true}
          footerBtnTitle
          filterList
          addButton
          addButtonTitle=""
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={pageNumber}
          setPageNumber={setPageNumber}
          noSecondSearchBar={true}
          isLoading={false}
          perPage={size}
          totalPage={Math.ceil(10 / 50)}
        />
      </div>
    </Layout>
  );
};

export default AttendanceReport;
