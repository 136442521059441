import { Box, Flex, Input, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import CTable from "../../../components/Table/table";
import { paidReportRequest } from "../../../modules/admin-modules/reports/Actions";

const PaiedReport = ({ setAdd, setSelectedData, searchTerm }) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));

  useEffect(() => {
    if (date) dispatch(paidReportRequest(date));
  }, [date]);

  const paidReport = useSelector((state) => state.reports.paidReport);
  const iaLoading = useSelector((state) => state.reports.iaLoading);
  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };

  const Data = paidReport?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      name: data?.name,
      group_name: data?.group?.name,
      phone: data?.phone,
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "name",
    },
    {
      Header: " المجموعة",
      accessor: "group_name",
    },
    {
      Header: " هاتف  الطالب",
      accessor: "phone",
    },
  ];
  const headers = [
    { label: "name", key: "name" },
    { label: "group_name", key: "group_name" },
    { label: "phone", key: "phone" },
    { label: "parent_phone", key: "parent_phoe" },
  ];
  return (
    <Layout>
      <div style={{ minHeight: "400px" }}>
        <Flex
          w="100%"
          my="30px"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          zIndex="10000000"
        >
          <Text fontSize="24px" fontWeight="600" color="main_1">
            تقرير الدفع
          </Text>
          <Flex w="60%" justifyContent="space-between" alignItems="center">
            <Input
              placeholder="Select Date and Time"
              size="md"
              type="date"
              w="60%"
              mx="8px"
              onChange={(e) => {
                const formatedDate = dayjs(e.target.value).format("YYYY-MM-DD");
                setDate(formatedDate);
              }}
            />

            {Data?.length != 0 && (
              <Box
                mx="10px"
                border="1px solid gray"
                borderRadius="8px"
                padding="10px"
              >
                <CSVLink
                  headers={headers}
                  data={Data?.length != 0 ? Data : []}
                  filename={"paid-report.csv"}
                  className="btn btn-primary"
                  target="_blank"
                >
                  Download
                </CSVLink>
              </Box>
            )}
            <LogoutMenu />
          </Flex>
        </Flex>

        <CTable
          selectedData={viewData}
          Columns={columns}
          Data={Data || []}
          path={"openModel"}
          Actions
          Title={``}
          subTitle=""
          btnTitle=""
          placeHolder=""
          noSearchBar={true}
          noFilter={true}
          footerBtnTitle
          filterList
          addButton
          addButtonTitle=""
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={pageNumber}
          setPageNumber={setPageNumber}
          noSecondSearchBar={true}
          isLoading={iaLoading}
          perPage={size}
          totalPage={Math.ceil(10 / 50)}
        />
      </div>
    </Layout>
  );
};

export default PaiedReport;
